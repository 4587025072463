/*eslint-disable */
// import blogModel from '../../Model/blog'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let blogs = {
  /**
   * blogList
   */
  async blogList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "blog_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at blogList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * blogView
   */
  async blogView (context, blogId) {
    try {      
      let post_data = new FormData();
      post_data.append('blog_id', blogId);
      return await request.curl(context, "blog_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at blogView() and Exception:",err.message)
    }    
  },

  /**
   * blogAdd
   */
  async blogAdd (context, blogObj) {
    try{
      let post_data = new FormData();
    
      let s3Attachments = []
      for (let key in blogObj) {
        if( key === 'blog_attach' && blogObj.blog_attach && blogObj.blog_attach.length ){
          for (let attachment of blogObj.blog_attach) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          if (blogObj[key]) {
            post_data.append(key, blogObj[key]);
          }
        }
      }
      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

      return await request.curl(context, "blog_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at blogAdd() and Exception:',err.message)
    }
  },

  /**
   * blogEdit
   */
  async blogEdit (context, blogObj) {
    try{
      let post_data = new FormData();
      
      let s3Attachments = []
      for (let key in blogObj) {
        if( key === 'blog_attach' && blogObj.blog_attach && blogObj.blog_attach.length ){
          for (const attachment of blogObj.blog_attach) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else {
          if (blogObj[key]) {
            post_data.append(key, blogObj[key]);
          }
        }
      }
      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

      return await request.curl(context, "blog_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at blogEdit() and Exception:',err.message)
    }
  },

  /**
   * blogDelete
   */
  async blogDelete (context, blogId) {
    try{
      let post_data = new FormData();
      
      post_data.append('blog_id', blogId);

      return await request.curl(context, "blog_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at blogDelete() and Exception:',err.message)
    }
  },

  /**
   * blogList
   */
  async blogPublishUnpublish (context, blogObj) {
    try{
      let post_data = new FormData();    
      for (let key in blogObj) {
        post_data.append(key, blogObj[key]);
      }
  
      return await request.curl(context, "blog_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at blogPublishUnpublish() and Exception:',err.message)
    }
  },

  /**
   * blogFilterList
   */
  async blogFilterList (context, filterName = "") {
    if (!filterName) {
      return
    }

    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      postData.append("filter_name", filterName)

      return await request.curl(context, "blog_filter_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at blog_filter_list() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  }
}

export {
  blogs
}
