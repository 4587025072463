<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class="webflow-card">
          <template v-slot:headerTitle>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="banner_box">
                <img class="img hero img-responsive banner_hero1" src="https://gide-assets.s3.us-east-1.amazonaws.com/MARKETING/Blogbanner1.png"/>
                <img class="img hero img-responsive banner_hero2" src="https://gide-assets.s3.us-east-1.amazonaws.com/MARKETING/Blogbanner2.png"/>
                <img class="img hero img-responsive banner_hero3" src="https://gide-assets.s3.us-east-1.amazonaws.com/MARKETING/Blogbanner3.png"/>
                <img class="img hero img-responsive banner_hero4" src="https://gide-assets.s3.us-east-1.amazonaws.com/MARKETING/Blogbanner4.png"/>
                <div class="banner_text">GIDE Blogs</div>
              </b-col>
            </b-row>
            <b-row class="row w-100 bg-white">
              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2"  style="margin: 8px 0px 0px 0px;">
                <b-form-input class="form-control"
                  @input="searchblogWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>

            <!-- Featured blogs  -->
            <b-row class="row w-100" style="display: flex;flex-wrap: nowrap;overflow: auto;">
              <b-col v-for="(item, index) in blogObjList" :key="index" class="mobileNoPadding col-12 col-sm-6 col-md-6 col-lg-6">
                <div v-if="(item.blog_category == 'Featured')">
                  <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height">
                    <div class="event-images">
                      <a style="cursor: pointer;" @click="openUrlInNewTab(`/blog/${item.blog_id}`)">
                        <img v-if="item.blog_img" :src="item.blog_img" style="" class="img-fluid image image_eventpost">
                        <div v-else class="image" style="display: flex; text-align: center; background-color: #EEEEEE; border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                          <span style="margin: auto; font-size: 111px;">{{item.blog_title.slice(0,1)}}</span>
                        </div>
                      </a>
                    </div>
                    <b-card-body class="iq-card-body rsvpCardBody pt-2">
                      <div class="webKitBox row" @click="openUrlInNewTab(`/blog/${item.blog_id}`)">
                        <div class="events-detail w-100 col-12" style="cursor: pointer;">
                          <h4 class="twoLineOnly blog_title mb-1">
                            <div class="blog_date">
                              {{ item.created_on|dateFormatMMMMDDYYYY }}
                            <span><b-badge class="float-right">{{ item.blog_category }}</b-badge></span>
                            </div>
                            {{ item.blog_title }}
                          </h4>
                        </div>
                        <p class="events-detail twoLineOnly col-12 mb-0" v-html="item.blog_desc"></p>
                      </div>
                      <div class="">
                        <span @click="openUrlInNewTab(`/blog/${item.blog_id}`)" class="read_more">READ MORE<i class="fa fa-long-arrow-right ml-3" aria-hidden="true"></i></span>
                      </div>
                    </b-card-body>
                  </iq-card>
                </div>
              </b-col>
            </b-row><!-- Featured blogs  -->

            <!--
            <b-row class="row w-100 bg-white">
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2"  style="margin: 8px 0px 0px 0px;">
                <select v-model="whereFilter.blog_category" class="form-control" @change="setFilters" title="Blog category name">
                  <option :value="null" disabled>Choose Blog Category</option>
                  <option :value=null>All</option>
                  <option v-for="(category, index) of blogFiltersList['blog_category']" :key="('blog_category'+index+1)" :value="category.blog_category">
                    {{category.blog_category}}
                  </option>
                </select>
              </b-col>
            </b-row>
            -->

            <b-row>
              <b-col v-for="(item, index) in blogObjList" :key="index" class="mobileNoPadding col-12 col-sm-6 col-md-6 col-lg-4 mt-4">
                <iq-card class="rounded iq-card-block iq-card-stretch iq-card-height">
                  <div class="event-images">
                    <a style="cursor: pointer;" @click="openUrlInNewTab(`/blog/${item.blog_id}`)">
                      <img v-if="item.blog_img" :src="item.blog_img" style="" class="img-fluid image image_eventpost">
                      <div v-else class="image" style="display: flex; text-align: center; background-color: #EEEEEE; border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                        <span style="margin: auto; font-size: 111px;">{{item.blog_title.slice(0,1)}}</span>
                      </div>
                    </a>
                  </div>
                  <b-card-body class="iq-card-body rsvpCardBody pt-2">
                    <div class="webKitBox row" @click="openUrlInNewTab(`/blog/${item.blog_id}`)">
                      <div class="events-detail w-100 col-12" style="cursor: pointer;">
                        <h4 class="twoLineOnly blog_title mb-1">
                          <div class="blog_date">
                            {{ item.created_on|dateFormatMMMMDDYYYY }}
                          <span><b-badge class="float-right">{{ item.blog_category }}</b-badge></span>
                          </div>
                          {{ item.blog_title }}
                        </h4>
                      </div>
                      <p class="events-detail twoLineOnly col-12 mb-0" v-html="item.blog_desc"></p>
                    </div>
                    <div class="">
                      <span @click="openUrlInNewTab(`/blog/${item.blog_id}`)" class="read_more">READ MORE<i class="fa fa-long-arrow-right ml-3" aria-hidden="true"></i></span>
                    </div>
                  </b-card-body>
                </iq-card>
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
              <b-row>
                <b-col sm="12" md="7">
                  <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                </b-col>
              </b-row>
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { blogs } from "../../FackApi/api/blog.js"
import moment from "moment"
import { socialvue } from "../../config/pluginInit.js"

export default {
  name: "blogPublicList",
  data () {
    return {
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "blog",
      blogObjList: [],
      filter: null,
      blogFiltersList: [],
      totalRows: 1,
      delObj: null,
      whereFilter: {},
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 100,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    await this.blogFilterList("blog_category")
    this.blogFilterList()
    this.blogList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.blogList(true)
    },
    /**
     * blogList
     */
    async blogList (loadViaLoadMore = false) {
      try {
        let whereFilter = {}
        // setting data for pagination
        whereFilter.currentBatchNo = this.currentBatchNo
        whereFilter.dataPerPage = this.dataPerPage
        whereFilter.search_param = this.search_param
        whereFilter.deleted = "3"
        for (const property in this.whereFilter) {
          if (this.whereFilter[property] && this.whereFilter[property] != "null" && this.whereFilter[property] != " ") {
            whereFilter[property] = this.whereFilter[property]
            // console.log(`${property}: ${this.whereFilter[property]}`)
          }
        }
        let blogListResp = await blogs.blogList(this, whereFilter)
        if (blogListResp.resp_status) {
          if (whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.blogObjList = [ ...blogListResp.resp_data.data ]
          }
          else {
            this.blogObjList = [ ...this.blogObjList, ...blogListResp.resp_data.data ]
          }

          // Adding the serial numbers:
          this.blogObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.blogObjList.length
        }
        else {
          this.currentBatchNo = this.currentBatchNo - 1
          this.showLoadMoreBtn = false
          this.toastMsg = blogListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at blogList() and Exception:", err)
      }
    },
    /**
     * openUrlInNewTab
     */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /**
     * searchblogWithParams
     */
    async searchblogWithParams () {
      if (this.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.blogList()
    },
    /*
     * blogFilterList
     */
    async blogFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let blogFilterListResp = await blogs.blogFilterList(this, filterName)
        if (blogFilterListResp) {
          this.blogFiltersList[filterName] = blogFilterListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at blog_filter_list_resp() and Exception:", err.message)
      }
    },
    /**
     * setFilters
    */
    async setFilters () {
      await this.blogList()
    }
  }
}
</script>
<style scoped>
 .webflow-card{
  box-shadow: none !important;
 }
 .blog_date {
  font-size: 14px;
  display: block;
  color: #acacac;
  font-weight: 500;
 }
 .blog_title{
  font-size: 20px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
 }
 .read_more {
  color: #e5252c;
  font-size: 13px;
  cursor: pointer;
 }
 .image{
  min-height: 250px;
  max-height: 250px;
 }

.hero {
  background: url("../../assets_gide/img/banners/Gide_background_banner_Blue.png") no-repeat;
  vertical-align: bottom;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.jumbotron{
  width: 100%;
}
@media (max-width: 380px){
  .banner_hero2,.banner_hero3,.banner_hero4{
    display: none;
  }
  .banner_text{
    font-size: 30px !important;
  }
}
@media only screen and (max-width:500px) and (min-width:380px){
  .banner_hero1,.banner_hero3,.banner_hero4{
    display: none;
  }
  .banner_text{
    font-size: 40px !important;
  }
}
@media only screen and (max-width:750px) and (min-width:500px){
  .banner_hero1,.banner_hero2,.banner_hero4{
    display: none;
  }
  .banner_text{
    font-size: 50px !important;
  }
}
@media (min-width:750px){
  .banner_hero1,.banner_hero2,.banner_hero3{
    display: none;
  }
}
.banner_box{
  position: relative;
}
.banner_text{
  position: absolute;
  color: #e5252c;
  text-align: center;
  text-transform: capitalize;
  font-family: Product Sans, sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 1.2;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  display: inline-table;
}
</style>
